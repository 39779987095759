import React from 'react'

import {
  Container,
  TitleContainer,
  DescriptionContainer,
  LeftAmoeba,
  RightAmoeba,
} from './style'

const Header = () => (
  <Container>
    <TitleContainer>
      <LeftAmoeba />
      <span>Entendendo Melhor</span>
      <h1>Artigos e Pesquisas</h1>
    </TitleContainer>
    <DescriptionContainer>
      <p>
        Queremos que você se mantenha atualizado as informações que
        especialistas estão divulgando sobre a Síndrome de Moebius. Aqui
        reunimos artigos e pesquisas que estão disponíveis para acesso gratuito:
      </p>
      <RightAmoeba />
    </DescriptionContainer>
  </Container>
)

export default Header
