import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import useArticles from 'graphql/useArticles'

import theme from 'styles/theme'

import Seo from 'components/Seo'
import Layout from 'components/Layout'
import Header from 'components/Articles/Header'
import List from 'components/Articles/List'
import Links from 'components/Articles/Links'

const Articles = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.biggestMobile})`,
  })

  const data = useArticles()
  const articles = data?.articles?.nodes
  const links = data.links.nodes

  return (
    <ThemeProvider theme={theme}>
      <Layout css={{ backgroundColor: theme.colors.primaryMedium }}>
        <Seo
          title="Artigos e Pesquisas"
          description="Queremos que você se mantenha atualizado as informações que especialistas estão divulgando sobre a Síndrome de Moebius. Aqui reunimos artigos e pesquisas que estão disponíveis para acesso gratuito:"
        />
        <Header />
        <List list={articles} isMobile={isMobile} />
        <Links links={links} />
      </Layout>
    </ThemeProvider>
  )
}
export default Articles
