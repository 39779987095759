import { graphql, useStaticQuery } from 'gatsby'

export default () =>
  useStaticQuery(graphql`
    query {
      articles: allContentfulArtigosPaginaArtigosEPesquisas {
        nodes {
          id
          title: titulo
          authors: autores
          url: linkDoArtigo
          abstract: resumo {
            text: resumo
          }
        }
      }
      links: allContentfulLinksPaginaArtigosEPesquisas {
        nodes {
          id
          title: titulo
          url: linkDoArtigo
        }
      }
    }
  `)
