import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  background: ${({ theme }) => theme.colors.backgroundMedium};
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding-bottom: 24px;
    }
  `}
`

export const Title = styled.p`
  width: 1200px;
  ${({ theme }) => theme.fonts.heading3};
  color: ${({ theme }) => theme.colors.primaryDarkest};
  margin-bottom: 64px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 299px;
      margin-bottom: 20px;
    }
  `}
`
export const OutsideLink = styled.a`
  width: 1200px;
  ${({ theme }) => theme.fonts.buttomLink};
  color: ${({ theme }) => theme.colors.neutralMedium};
  margin-bottom: 40px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 299px;
    }
  `}
`
