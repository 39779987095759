import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/articles-vector1.svg'
import Amoeba2 from 'assets/vectors/articles-vector2.svg'
import Arrow from 'assets/vectors/arrow-head.svg'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 195px 0px 122px;
  background: ${({ theme }) => theme.colors.backgroundMedium};
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      justify-content: center;
      padding: 66px 0px 200px;
    }
  `}
`

export const CardsContainer = styled.div`
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      justify-content: center;
      padding: 0px 28px;
    }
  `}
`

export const Card = styled.div`
  width: 579px;
  height: 359px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 52px 33px 33px;
  margin-bottom: 50px;
  background: ${({ theme }) => theme.colors.backgroundLight};
  z-index: 2;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 430px;
      height: 420px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 340px;
      height: 500px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 301px;
      height: 460px;
      padding: 38px 16px 38px 33px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      width: 315px;
      height: 520px;
      padding: 38px 16px 38px 16px;
    }
  `}
`

export const CardTop = styled.div``
export const CardBottom = styled.div``

export const Title = styled.p`
  ${({ theme }) => theme.fonts.mediumTextBlack};
  color: ${({ theme }) => theme.colors.neutralDarkest};
  margin-bottom: 16px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.mediumTextBold};
    }
  `}
`

export const Authors = styled.p`
  ${({ theme }) => theme.fonts.smallTextSemiBold};
  color: ${({ theme }) => theme.colors.primaryDarkest};
  margin-bottom: 28px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextSemiBold};
      margin-bottom: 24px;
    }
  `}
`

export const AbstractTitle = styled.p`
  ${({ theme }) => theme.fonts.buttomLink};
  color: ${({ theme }) => theme.colors.neutralMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-bottom: 8px;
    }
  `}
`
export const Abstract = styled.p`
  ${({ theme }) => theme.fonts.smallTextSemiBold};
  color: ${({ theme }) => theme.colors.neutralMedium};
`

export const OutsideLink = styled.a`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fonts.buttomLink};
  color: ${({ theme }) => theme.colors.primaryDarkest};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextSemiBold};
    }
  `}
`

export const TopAmoeba = styled(Amoeba1)`
  position: absolute;
  top: 48px;
  left: 0px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      transform: rotate(24deg);
      top: -6px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 226px;
      height: 202px;
      left: -67px;
    }
  `}
`
export const BottomAmoeba = styled(Amoeba2)`
  position: absolute;
  right: 0px;
  bottom: 0px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 500px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 308px;
      height: 272px;
      right: -56px;
      bottom: 62px;
    }
  `}
`

export const ArrowIcon = styled(Arrow)`
  margin-left: 6px;
`
