import React from 'react'

import {
  Container,
  TopAmoeba,
  BottomAmoeba,
  CardsContainer,
  Card,
  CardTop,
  CardBottom,
  Title,
  Authors,
  AbstractTitle,
  Abstract,
  OutsideLink,
  ArrowIcon,
} from './style'

const List = ({ list, isMobile }) => {
  const truncate = (text, size) =>
    text.length > size ? `${text.substring(0, size)}[...]` : text

  return (
    <Container>
      <TopAmoeba />
      <CardsContainer>
        {list.map((item) => (
          <Card key={item.id}>
            <CardTop>
              <Title>
                {typeof window !== `undefined` &&
                  isMobile &&
                  truncate(item.title, 70)}
                {typeof window !== `undefined` && !isMobile && item.title}
              </Title>
              <Authors>{item.authors}</Authors>
              <AbstractTitle>RESUMO</AbstractTitle>
              <Abstract>
                {typeof window !== `undefined` &&
                  !isMobile &&
                  truncate(item?.abstract?.text, 420)}
                {typeof window !== `undefined` &&
                  isMobile &&
                  truncate(item?.abstract?.text, 280)}
              </Abstract>
            </CardTop>
            <CardBottom>
              <OutsideLink href={item.url} target="_blank" rel="noreferrer">
                Ler Artigo <ArrowIcon />
              </OutsideLink>
            </CardBottom>
          </Card>
        ))}
      </CardsContainer>
      <BottomAmoeba />
    </Container>
  )
}
export default List
