import React from 'react'

import { Container, Title, OutsideLink } from './style'

const Links = ({ links }) => (
  <Container>
    <Title>Links de artigos</Title>
    {links.map((link) => (
      <OutsideLink href={link.url} target="_blank" rel="noreferrer">
        {link.title}
      </OutsideLink>
    ))}
  </Container>
)

export default Links
